<template>
  <RouterLink :exact="exact" :to="$lang.route(linkTo)"><slot />{{ label }}</RouterLink>
</template>

<script>
export default {
  name: 'PageLink',
  props: {
    to: { type: Object, default: null },
    label: { type: String, default: null },
    pageId: { type: Array, default: Array },
    anchorId: { type: String, default: null },
    exact: { type: Boolean, default: false }
  },
  computed: {
    linkTo() {
      const link = this.to || { name: this.$wpGetPageSlug(this.pageId) }
      if (this.anchorId) {
        link.hash = this.anchorId
        link.meta = { offset: this.$device.laptop ? 100 : 80 }
      }
      return link
    }
  }
}
</script>
